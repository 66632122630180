import React, { useState } from "react";
import { Button, Container, Message, Divider } from "semantic-ui-react";

export const ExternalApi = () => {
  const [message, setMessage] = useState("");
  const apiId = process.env.REACT_APP_API_ID;
  const apiEndpoint = `https://${apiId}.execute-api.us-east-1.amazonaws.com/dev`;

  const callApi = async () => {
    try {
      const response = await fetch(`${apiEndpoint}/ping`);

      const responseData = await response.json();

      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const callSecureApi = async () => {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer 1234");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const response = await fetch(`${apiEndpoint}/ping`, requestOptions);
      const responseData = await response.json();
      setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
      console.log("error", error);
    }
  };

  return (
    <Container>
      <h1>External API</h1>
      <p>
        You use will use a button to call an external API using an access token,
        and the API will validate it using the API's audience value.{" "}
        <strong>This route should be private</strong>.
      </p>
      <Button.Group vertical>
        <Button onClick={callApi} primary>
          Get Public Message
        </Button>
        <Divider />
        <Button onClick={callSecureApi} secondary>
          Get Private Message
        </Button>
      </Button.Group>

      {message && (
        <div>
          <Divider />
          <Message
            header="response from the api"
            content={JSON.stringify(message, null, 2)}
          ></Message>
        </div>
      )}
    </Container>
  );
};

export default ExternalApi;
