import React from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

let courseList = require("./courseData.json");

const CoursesTable = () => (
  <Table celled unstackable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={10}>Course</Table.HeaderCell>
        <Table.HeaderCell width={3}>Date</Table.HeaderCell>
        <Table.HeaderCell width={3}>Type</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <Link to="/works/courses/serverless">
            {" "}
            Developing AWS Serverless applications{" "}
          </Link>
          <p style={{ color: "black" }}>
            {" "}
            Cloud is one of those technologies that empower a developer. But
            Serverless could be all a developer could dreamed about when
            creating applications.
          </p>
        </Table.Cell>
        <Table.Cell>{courseList.serverless.Date}</Table.Cell>
        <Table.Cell>{courseList.serverless.Type}</Table.Cell>
      </Table.Row>
    </Table.Body>

    <Table.Footer></Table.Footer>
  </Table>
);

export default CoursesTable;
