import React, { Component } from "react";
import dgd_photo from "../../media/image/david_canvas_1_54.png";
import { Container } from "semantic-ui-react";

export class MyPhoto extends Component {
  render() {
    return (
      <>
        <Container fluid>
          <img className="ui fluid image" src={dgd_photo} alt="MySelf" />
          <div className="centered">
            <h2>
              {/*<p style={{ fontFamily: "Dancing Script, cursive" ,color: 'blue' }}>Hi there,</p>*/}
            </h2>
            <h1>
              <p>
                {/*<em style={{ color: 'blue' }}> I'm David Gutierrez</em>*/}
              </p>
            </h1>
          </div>
        </Container>
        ;
      </>
    );
  }
}
