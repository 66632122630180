import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class MyCourses extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">Courses</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>
              See a list of my courses and their prices{" "}
              <a href={window.location.origin + "/works"}>here.</a>
            </li>
            <li>
              This is a list of courses I teach. Those are paid courses and you
              will get a high quality, hands-on course content. I teach to only
              12 people at the time so I can concentrate in helping you develop
              your skills.
            </li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
