import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class CurrentSkills extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="left"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">Current Skills</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>Python 5</li>
            <li>Javascript, NodeJS, Jest  4</li>
            <li>React 4</li>
            <li>Bash 4</li>
            <li>Dockers 4</li>
            <li>IaC Serverless Framework and AWS CDK 4</li>
            <li>AWS Cloud Development Serverless 5</li>
            <li>AWS Cloud Development Kubernetes 4</li>
            <li>AWS Cloud 4</li>
            <li>Network Security, 5</li>
            <li>Application Security 5</li>
            <li>Machine Learning 3</li>
            <li>Artificial Intelligence 3</li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
