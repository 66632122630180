import Axios from "axios";

const apiId = process.env.REACT_APP_API_ID;
const apiEndpoint = `https://${apiId}.execute-api.us-east-1.amazonaws.com/dev`;

export async function getProjects(idToken) {
  console.log("Fetching projects");
  console.log("token:", idToken);

  const response = await Axios.get(`${apiEndpoint}/projects`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
      // Authorization: `Bearer 1234`,
    },
  });
  console.log("Projects", response.data);
  return response.data.Items;
}

export async function createProject(idToken, newProj) {
  console.log("Creating new project", newProj);
  console.log("token:", idToken);

  const response = await Axios.post(`${apiEndpoint}/projects`, newProj, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
      // Authorization: "Bearer 1234",
    },
  });
  console.log("Projects", response.data);
  return response.data;
}

export async function getUploadUrl(idToken, imageName) {
  // const response = await Axios.post(`${apiEndpoint}/projects/${projectId}/attachment`, '', {
  const response = await Axios.post(
    `${apiEndpoint}/projects/attachment/${imageName}`,
    "",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
        // Authorization: `Bearer 1234`,
      },
    }
  );
  return response.data.uploadUrl;
}

export async function uploadFile(uploadUrl, file) {
  await fetch(uploadUrl, {
    method: "PUT",
    body: file,
  });
}

export async function registerUser(info) {
  //This method sends user email and name when he wants to register for a course
  console.log("registering New User", info);

  return await Axios.post(`${apiEndpoint}/register`, info, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}
