import React, { Component } from "react";
import { List, Button } from "semantic-ui-react";
import CourseRegistrationModal from "./courseRegistrationModal";

let courseList = require("./courseData.json");

export class ServerlessCourseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      courseName: courseList.serverless.Name,
      courseDate: courseList.serverless.Date,
      courseType: courseList.serverless.Type,
    };
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    let modal;

    if (this.state.isModalOpen) {
      modal = (
        <CourseRegistrationModal
          courseName={this.state.courseName}
          courseDate={this.state.courseDate}
          courseType={this.state.courseType}
          openModal={this.openModal}
          closeModal={this.closeModal}
          open={this.state.isModalOpen}
        />
      );
    }

    return (
      <div>
        {modal}
        <Button primary onClick={() => this.openModal()}>
          Register
        </Button>
        <List bulleted style={{ color: "black" }}>
          <List.Item as={"ul"}>
            Introduction: AWS Serverless
            <List.List>
              <List.Item as="li">
                The importance of Serverless for a developer{" "}
              </List.Item>
              <List.Item as="li">CI/CD pipelines options </List.Item>
              <List.Item as="li">
                CI/CD pipelines selected for this training{" "}
              </List.Item>
              <List.Item as="li">
                {" "}
                Planing, Costs and when to use Serverless{" "}
              </List.Item>
            </List.List>
          </List.Item>

          <List.Item>
            Chapter 1. Creating a Mock Authentication with JWT and Auth0.
            Deploying SPA using AWS Amplify
            <List.List>
              <List.Item as="li">Explanation of the project</List.Item>
              <List.Item as="li">Front End creation</List.Item>
              <List.Item as="li">
                Front End Deployment using AWS Amplify
              </List.Item>
              <List.Item as="li">Serverless Framework backend</List.Item>
              <List.Item as="li">
                Setting up correct .env variables in React
              </List.Item>
              <List.Item as="li">
                Lambda function /ping endpoint triggered by AWS API Gateway
                event
              </List.Item>
              <List.Item as="li">
                Lambda function /auth endpoint triggered by authentication event
              </List.Item>
              <List.Item as="li">Auth0 integration</List.Item>
              <List.Item as="li">POSTMAN collection </List.Item>
              <List.Item as="li">Integrating the front end </List.Item>
            </List.List>
          </List.Item>
          <List.Item as="ul">
            Chapter 2. DynamoDB integration, API Gateway Model Validation and
            Logging. Validations and integration types
            <List.List>
              <List.Item as="li">
                Create the Lambda createProject Serverless endpoint
              </List.Item>
              <List.Item as="li">DynamoDB integration</List.Item>
              <List.Item as="li">Body Request Model Validation</List.Item>
              <List.Item as="li">
                Body Request Model Validation limitations when using
                lambda-proxy integration
              </List.Item>
              <List.Item as="li">
                Input Validation when using lambda integration{" "}
              </List.Item>
              <List.Item as="li">
                Setting up correct .env variables in React
              </List.Item>
              <List.Item as="li">Refactoring logging</List.Item>
              <List.Item as="li">
                Adding more lambda function to our API Gateway
              </List.Item>
              <List.Item as="li">POSTMAN collection</List.Item>
              <List.Item as="li"> Integrating the front end</List.Item>
            </List.List>
          </List.Item>
          <List.Item as={"ul"}>
            Chapter 3. FrontEnd code modifications and more AWS Serverless
            Patterns
            <List.List>
              <List.Item as="li">
                Frontend:Modify the frontend to show a list of Projects
              </List.Item>
              <List.Item as="li">
                Frontend: Create a GET client AJAX request to retrieve new
                projects by consuming the AWS API Lambda /getProjects
              </List.Item>
              <List.Item as="li">
                Frontend: POST client async AJAX request to create new projects
                by consuming the AWS API Lambda POST /createProject
              </List.Item>
              <List.Item as="li">
                Backend: Modify the code to accept a github repo entry into
                DynamoDB as an optional parameter. (**MODEL VALIDATION
                PATTERN**)
              </List.Item>
              <List.Item as="li">
                Frontend: Creating a component to add projects
              </List.Item>
              <List.Item as="li">
                Frontend: Creating the FrontEnd component to add Projects :
                createProjects
              </List.Item>
              <List.Item as="li">
                Backend: Create Lambda EndPoint for S3 Signed URL(**S3 PRESIGNED
                URL PATTERN**)
              </List.Item>
              <List.Item as="li">Schematic of our AWS Deployment</List.Item>
              <List.Item as="li">POSTMAN collection</List.Item>
            </List.List>
          </List.Item>
          <List.Item as={"ul"}>
            Chapter 4. Integrating JWT Authentication. AWS Secrets.
            <List.List>
              <List.Item as="li">
                Analyzing a possible symmetric signature validation of JWT
                tokens.
              </List.Item>
              <List.Item as="li">
                Where to store the secret ? AWS Secret service.
              </List.Item>
              <List.Item as="li">
                A better solution using asymmetric (certificates) signature
                validation of JWT tokens
              </List.Item>
              <List.Item as="li">POSTMAN collection.</List.Item>
            </List.List>
          </List.Item>
          <List.Item as={"ul"}>
            Chapter 5: Websockets intro,Data Streams, Error Handling
            <List.List>
              <List.Item as="li">
                API gateway Websockets. Websockets with serverless framework
              </List.Item>
              <List.Item as="li">Text Search : Elasticsearch</List.Item>
              <List.List>
                <List.Item as="li">
                  Elasticsearch and the bonus data visualization and exploration
                  tool Kibana
                </List.Item>
                <List.Item as="li"> Why we need an Stream Service ?</List.Item>
                <List.Item as="li">
                  AWS Kinesis Stream Services for decoupling the
                  producer(DynamoDB) and consumer (ElasticSearch) of the streams
                </List.Item>
                <List.Item as="li">
                  DynamoDB has its own Stream services{" "}
                </List.Item>
                <List.Item as="li">
                  Differences between DynamoDb streams and Kinesis Streams
                </List.Item>
                <List.Item as="li">
                  Our selection : Using DynamoDB Streams
                </List.Item>
              </List.List>
            </List.List>
          </List.Item>

          <List.Item as={"ul"}>
            Chapter 6: Decoupling synchronous connections between API Gateway
            and Lambda
            <List.List>
              <List.Item as="li">
                Time Limitation on Serverless Architecture
              </List.Item>
              <List.Item as="li">Introduction to SQS</List.Item>
              <List.Item as="li">
                Using SQS to create an asynchronous connection between the API
                request and Lambda
              </List.Item>
              <List.Item as="li">
                Using and SQS event that triggers a lambda function
              </List.Item>
              <List.Item as="li">Introduction to Step functions</List.Item>
              <List.Item as="li">
                Using the lambda function as a proxy to between SQS and
                StepFunction.Invoking the Step Function from the lambda function
              </List.Item>
              <List.Item as="li">
                Modifying the front end app by adding a loader and a modal
              </List.Item>
            </List.List>
          </List.Item>

          <List.Item as={"ul"}>
            Chapter 7: AWS Step Functions and Artificial Intelligence .
            <List.List>
              <List.Item as="li">AWS Step Function, quick view</List.Item>
              <List.Item as="li">
                AWS Artificial Intelligence framework "Recoknition"
              </List.Item>
              <List.Item as="li">
                Modifying the "StartAt" Task in the Step function, creating more
                states
              </List.Item>
              <List.Item as="li">
                Removing the S3 upload event and renaming the function
              </List.Item>
            </List.List>
          </List.Item>

          <List.Item as={"ul"}>
            Chapter 8: Web Sockets and AWS API Gateway
            <List.List>
              <List.Item as="li">Creating a Web Socket </List.Item>
              <List.Item as="li">
                Communicating a successful task to the user
              </List.Item>
              <List.Item as="li">
                Communicating a failure in the task to the user{" "}
              </List.Item>
              <List.Item as="li">Uploading the new task to Dynamo DB</List.Item>
              <List.Item as="li">Modifying the Front End</List.Item>
              <List.Item as="li">Securing the WebSocket</List.Item>
            </List.List>
          </List.Item>

          <List.Item as={"ul"}>
            Chapter 9: SES Service to send an email to the instructor
            <List.List>
              <List.Item as="li">
                Using SES to send and email. Architecting the solution with SQS{" "}
              </List.Item>
              <List.Item as="li">
                Body Request Model Validation limitations when using
                lambda-proxy integration
              </List.Item>
              <List.Item as="li">
                API Gateway throttling. Configuring method-level throttling
              </List.Item>
              <List.Item as="li">
                Validation the input manually using a decorator
              </List.Item>
              <List.Item>
                Two-way TLS authentication between the client and the server
              </List.Item>
              <List.Item as="li">Creating a Form in the client side </List.Item>
              <List.Item as="li">
                Creating the SQS Queue, even handlers and using SES{" "}
              </List.Item>
              <List.Item as="li">Detailing all Security concerns </List.Item>
              <List.Item as="li">POSTMAN collection Testing </List.Item>
            </List.List>
          </List.Item>

          <List.Item as={"ul"}>
            Chapter 10: Securing Serverless Applications in AWS
            <List.List>
              <List.Item as="li">
                Complexity of Security in Serverless Architecture{" "}
              </List.Item>
              <List.Item as="li">Securing the infrastructure </List.Item>
              <List.Item as="li">Securing the front end code </List.Item>
              <List.Item as="li">Securing the back end code </List.Item>
            </List.List>
          </List.Item>
        </List>
      </div>
    );
  }
}
