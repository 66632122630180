import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class MyInterest extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">My Interests:</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>All sorts of Python applications</li>
            <li>
              Python and JS language as these are the language I use day to day
            </li>
            <li>AWS Based Systems</li>
            <li>Artificial Intelligence and Machine Learning applications</li>
            <li>Security</li>
            <li>Helicopters and Planes</li>
            <li>
              South Florida National Parks outdoor activities (camping,
              canoeing, wildlife, and star observation)
            </li>
            <li>
              Social Justices subjects I’m interested in race and poverty issues
            </li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
