import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class LessonLearned extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">Lessons I have learned throughout my career:</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>
              It is much better to do what you love and know a few things really
              deep than trying to reach multiple areas.
            </li>
            <li>
              Curiosity is like a strong wind in a calm lake, it will keep you
              pushing in some directions. Sometimes it is the directions you
              want to arrive, sometimes it is not.
            </li>
            <li>
              Keep curious always but keep your curiosity under control. Most
              people do not understand the happiness of a curious mind. I have
              learned that you have to be curious about a lot of things but
              unfortunately, you can not take more than one route.
            </li>
            <li>
              The direction you paddle and steer your canoe will get you to one
              destination. Of course, you have to take into account the wind. Be
              careful with the currents. Sometimes are visible, sometimes are
              invisible but they might be there just under your canoe. Knowing
              their existence will help you understand what you have to do to
              arrive at your destination. Dismiss them and you will arrive only
              after exhaustion or maybe you will never arrive. The most
              important thing is to understand that both the wind and the
              currents, sometimes could be your allies, sometimes could work
              against you. but it is your willingness, skills, and understanding
              that could see them both as beautiful forces that will always be
              present, and you can maybe get them to cooperate with you. Those
              forces don’t understand personal relations. Nature is not
              personal, it is not about you, so understand them, rethink and
              adapt and follow your path.
            </li>
            <li>
              Not even a team of genius can achieve success, when they lack
              chemistry and empathy, and don’t cooperate deeply between
              them.They will eventually burn out, their results will be mediocre
              and they will be outperformed by other teams, that even with less
              brighten individuals, is able to work together
            </li>
            <li>A team is not necessarily a conglomeration of starts</li>
            <li>
              Microaggressions are : interrupting others while they are talking,
              give advises that were not asked , plan based on unrealistic
              goals, gossiping, do not give other or yourself a second chance
            </li>
            <li>
              If you want to succeed, make yourself vulnerable. The dreamers ,
              the creators, the people who succeed in life all of them did it.
              For that try to pursuit without knowing if you will success
            </li>
            <li>
              Just listen, ask for more information, instead of interrupting and
              give your own , not asked opinion
            </li>
            <li>Meditate, exercise, breath, and be alone in nature</li>
            <li>
              How many days have you been without human contact, without talking
              to anyone , without cell phone , text messages , immerse in
              outdoors with no other sound than those of the natural world ?
            </li>
            <li>
              Looks for the needs of your team, your company and your
              family.They are right there. There is no way that individuals and
              institutions had a cohesive , ordered list and plan of all their
              needs and vulnerabilities. It does not mean there is nothing you
              can do about it
            </li>
            <li>
              Extreme ownership is always a good solution. It means you are
              responsible for all failures when you are the leader. Not everyone
              could be so fortunate.
            </li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
