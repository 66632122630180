import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";
import { ServerlessCourseDetails } from "./Serverless_Course_Details";
import { Link } from "react-router-dom";

export class AWSServerlessCourse extends Component {
  render() {
    return (
      <Container
        text
        textAlign="justified"
        color="black"
        style={{ marginTop: "3em" }}
      >
        <Header as="h2">Developing AWS Serverless applications</Header>

        <p style={{ color: "black" }}>
          This course and all material and code on it has been created by
          <Link to="/"> David Gutierrez </Link>for training purpose to
          developers.
          <p>
            This course is intent for developers who already have an advanced
            knowledge of a high level programing language as well as a good
            understanding of Web Technology and Architecture. A basic
            understanding of AWS will be also necessary. This is an Advance
            course .We assume you know enough about frontEnd development so yo
            can easy create a SPA and modify it to add new functionality. During
            this training you will code from zero a front End SPA and Backend
            Serverless application and will use best development code patterns
            and practices for AWS Development with Serverless Services. Since
            day one you will deploy your application to AWS and connect your
            Code Repository to your application in AWS through a CI/CD pipeline.
            You will learn everything you need to know to start creating complex
            high quality and secure application in AWS using Serverless
            Technology and apply Artificial Intelligence recognition patterns to
            your app.
          </p>
        </p>

        <ServerlessCourseDetails></ServerlessCourseDetails>
      </Container>
    );
  }
}
