import React from "react";
import { Button, Checkbox, Form, Message, Modal } from "semantic-ui-react";
import { registerUser } from "../../../api/projects-api";

const CourseRegistrationModal = (props) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [requestMessage, setRequestMessage] = React.useState();
  const {
    courseName,
    courseDate,
    courseType,
    openModal,
    closeModal,
    open,
  } = props;

  const registerForm = (e) => {
    e.preventDefault();
    //Takes values from the form in the Modal and send an email
    registerUser({
      email: email,
      name: name,
      course: courseName,
      date: courseDate,
      type: courseType,
    })
      .then((response) => {
        setRequestMessage(
          createNewMessage("Success!", response.data, { positive: true })
        );
      })
      .catch(({ response }) => {
        // we are performing this if statement because
        // API sometimes returns a string, other times returns a JSON object.
        if (response.data.error) {
          setRequestMessage(
            createNewMessage("Something went wrong!", response.data.error, {
              negative: true,
            })
          );
        } else {
          setRequestMessage(
            createNewMessage("Something went wrong!", response.data, {
              negative: true,
            })
          );
        }
      });
  };

  const handleOnChange = (e) => {
    if (e.target.name === "name") {
      setName(e.target.value);
    }
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  return (
    <Modal
      as={Form}
      onSubmit={(e) => registerForm(e)}
      onClose={() => closeModal()}
      onOpen={() => openModal()}
      open={open}
    >
      <Modal.Header>
        Registration for : {courseName}, {courseDate}, {courseType}
      </Modal.Header>
      <Modal.Content>
        {requestMessage}
        <Form>
          <Form.Field>
            <label>Your Name </label>
            <input name="name" placeholder="Name" onChange={handleOnChange} />
          </Form.Field>
          <Form.Field>
            <label>Your Email</label>
            <input name="email" placeholder="Email" onChange={handleOnChange} />
          </Form.Field>
          <Form.Field>
            <Checkbox label="I agree to the Terms and Conditions" />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary>Register</Button>
        <Button secondary onClick={closeModal}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

// helper function that creates Message Components
const createNewMessage = (header, text, props) => (
  <Message {...props}>
    <Message.Header>{header}</Message.Header>
    <p>{text}</p>
  </Message>
);

export default CourseRegistrationModal;
