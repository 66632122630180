import React, { useState, useEffect } from "react";
import { Header } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import ProjectList from "./projectList";
import CreateProject from "./createProject";
import Login from "../login";
import { getProjects } from "../../api/projects-api";

const domain = "dev-g54s5vhm.us.auth0.com";

const Projects = (props) => {
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(true);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  console.log(loadingItems);

  const fetchProjects = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      const projs = await getProjects(accessToken);
      setItems(projs);
      setLoadingItems(false);
    } catch (e) {
      alert(`Failed to fetch projects: ${e.message}`);
    }
  };

  useEffect(fetchProjects, []);

  //Conditional return only if authenticate

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <Login></Login>
          <CreateProject updateListOfItems={fetchProjects} />
          <ProjectList listOfProjects={items} />
        </div>
      ) : (
        <Header as="h3">Please LogIn</Header>
      )}
    </div>
  );
};

export default Projects;
