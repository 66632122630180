import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class AboutMe extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">About Me:</Header>
          <p style={{ color: "black" }}>
            My name is David Gutierrez and I’m an Electric Automatic Control
            Engineer. I’ve spent most of my professional career working in
            Application Security and Software Development. I am very passionate
            about software engineering. My passion is to create Amazon AWS
            based systems. I love to combine my programming skills along with my AWS
            cloud knowledge to create systems that could be useful for others.
            My Amazon Cloud and development skills allow me to
            significantly speed up my workflow in a cost effective manner.
          </p>
          <p style={{ color: "black" }}>
            My current goal is to incorporate Artificial Intelligence and
            Machine Learning into my AWS cloud-based applications so they could
            be even more robust, secure, and flexible. The applications I work
            on are centered around making a direct impact on the lives of
            others. Currently, I am working on an application that improves
            personal health by promoting outdoor activities. My office work
            develops about AWS Cloud Development and Application Security. I
            develop AWS cloud application and integration systems with Python
            and Java Script. Mostly Serverless application.
          </p>
        </Container>
      </>
    );
  }
}
