import React from "react";
import "./App.css";
import Home from "../home";
import Login from "../login";
import Projects from "../projects";
import Works from "../works";
import ExternalApi from "../externalapi";
import { BrowserRouter, Route } from "react-router-dom";
import CreateProject from "../projects/createProject";
import { AWSServerlessCourse } from "../works/courses/AWS_Serverless_Course";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={Login} />
          <Route path="/external-api" exact component={ExternalApi} />
          <Route path="/projects" exact component={Projects} />
          <Route path="/project/create" exact component={CreateProject} />
          <Route
            path="/works/courses/serverless"
            exact
            component={AWSServerlessCourse}
          />
          <Route path="/works" exact component={Works} />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;

//Create Basic React Class Component: rcjc
// Create React Class Component: rcfc
