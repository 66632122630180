import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class AchievingYourGoals extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">What do I do to achieve my dreams?</Header>

          <p style={{ color: "black" }}>Number 1: Creating</p>

          <p style={{ color: "black" }}>
            The best way to achieve your dreams is by creating. You create and
            help others and eventually, you can end up in a path where all your
            interest converges. Put your knowledge and your skill to serve your
            company, your community, to help others and the day will come when
            you could see that more than one of your passions converge.
          </p>

          <p style={{ color: "black" }}>Number 2: Be a leader.</p>
          <p style={{ color: "black" }}>
            Take into account that your team and people who surround you are the
            most important force and you should pay close attention to them.
            LISTEN TO THEM. JUST LISTEN. There is always a way to help and grow
            if you really listen to others. Be a leader always no matter what.
            Keep ownership especially during the failures. You are the ultimate
            responsibility of your goals
          </p>

          <p style={{ color: "black" }}>Number 3: Education</p>
          <p style={{ color: "black" }}>
            Try to always increase your knowledge about the subjects you are
            passionate about and you need for your professional career
          </p>

          <p style={{ color: "black" }}>Examples:</p>

          <p style={{ color: "black" }}>
            I love Python, so I constantly create Python applications and study
            the language, I love AWS Cloud so I constantly use it and study it,
            and explain it to my friends and my co-workers. I love the
            advantages of Artificial intelligence and Machine Learning, so I try
            to study them, apply them, and explain them to others. I work in
            security so I try to create secure systems, study item and keep
            others involved
          </p>
        </Container>
      </>
    );
  }
}
