const wsId = process.env.REACT_APP_WS_ID;
const region = process.env.REACT_APP_REGION;
const stage = process.env.REACT_APP_STAGE;
const wsEndpoint = `wss://${wsId}.execute-api.${region}.amazonaws.com/${stage}`;

export async function webSocketConnect(attachUrl) {
  try {
    let msg = {
      action: "sendmessage",
      data: attachUrl,
    };
    var ws = new WebSocket(wsEndpoint);
    ws.onopen = () => {
      // on connecting, send the attachmentUrl to the server
      console.log(ws.readyState);
      ws.send(JSON.stringify(msg));
    };

    // ws.onmessage = (event) => {
    //   alert(event.data);
    //   ws.close();
    // };

    return ws;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function webSocketClose(ws, attachUrl) {
  try {
    let msg = {
      action: "disconnect",
      data: attachUrl,
    };

    ws.onclose = () => {
      // on closing the web socket, the disconnect route is called
      console.log(ws.readyState);
      ws.send(JSON.stringify(msg));
    };

    ws.close(msg);
  } catch (e) {
    console.log(e);
    return false;
  }
}
