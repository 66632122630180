import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class MySystem extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">My System</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>Listen to others. Just listen</li>
            <li>Extreme Ownership</li>
            <li>Prioritize your customers and your teams</li>
            <li>Move out of your own way</li>
            <li>Be driven by passion and not for the paycheck</li>
            <li>Keep curious</li>
            <li>Ask questions</li>
            <li>Explain to others</li>
            <li>Create as much as you can. Apply what you love and know</li>
            <li>Deep your knowledge</li>
            <li>Be yourself</li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
