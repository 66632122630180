import React, { Component } from "react";
import { Container, Header } from "semantic-ui-react";

export class Projects extends Component {
  render() {
    return (
      <>
        <Container
          text
          textAlign="justified"
          color="black"
          style={{ marginTop: "3em" }}
        >
          <Header as="h2">Projects</Header>
          {/*<p style={{"color": "black"}}>*/}
          <ul style={{ color: "black" }}>
            <li>
              See a list of my projects{" "}
              <a href={window.location.origin + "/login"}>here.</a> (Note that
              this requires authentication)
            </li>
            <li>
              I can also check your code and make recommendations for any
              Python, React, AWS Serverless, AWS Kubernetes or Machine Learning
              application you have created .Share yor code ,schematic and GitHub
              repo and I will take a look to it.
            </li>
          </ul>
          {/*</p>*/}
        </Container>
      </>
    );
  }
}
