import React, { useState } from "react";
import {
  Button,
  Form,
  Header,
  Container,
  Input,
  TextArea,
  Loader,
  Segment,
  Dimmer,
  Image,
  Modal,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getUploadUrl,
  uploadFile,
  createProject,
} from "../../api/projects-api";

import { webSocketConnect } from "../../api/ws-api";

const domain = "dev-g54s5vhm.us.auth0.com";

const CreateProject = (props) => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const [newFile, SetNewFile] = useState([]);
  const [title, SetTitle] = useState("");
  const [description, SetDescription] = useState("");
  const [repo, SetRepo] = useState("");
  const [isCreatingProject, SetCreatingProjectFlag] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const fileChange = (event) => {
    console.log(event);
    SetNewFile(event.target.files[0]);
  };

  const handleTitleChange = (event) => {
    console.log(event);
    SetTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    console.log(event);
    SetDescription(event.target.value);
  };

  const handleGitHubChange = (event) => {
    console.log(event);
    SetRepo(event.target.value);
  };

  const clearForm = () => {
    SetNewFile([]);
    SetTitle("");
    SetDescription("");
    SetRepo("");
  };

  const onFormSubmit = async () => {
    SetCreatingProjectFlag(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      const uploadUrl = await getUploadUrl(accessToken, newFile.name);
      const attachmentUrl = uploadUrl.split("?")[0];

      // create a websocket and upload file
      let ws = await webSocketConnect(attachmentUrl);
      await uploadFile(uploadUrl, newFile);

      // add event listener to websocket
      // so that when project is created successfully,
      // we update project list
      ws.addEventListener("message", (event) => {
        // if data says project is ok, update project list using props.updateListOfItems
        alert(event.data);
        props.updateListOfItems();
        ws.close();
      });

      //TODO: Include in the new item two new fields
      // userPicture and userName
      // They can be get from useAuth0 user property
      // email: "gutierrez.david28@gmail.com"
      // email_verified: true
      // family_name: "G"
      // given_name: "D"
      // locale: "en"
      // name: "D G"
      // nickname: "gutierrez.david28"
      // picture: "https://lh4.googleusercontent.com/-b-uGAxByvX8/AAAAAAAAAAI/AAAAAAAACLs/AMZuucnXElLfi3Xp0sqN2uOHPFEgp8rzXg/s96-c/photo.jpg"
      // sub: "google-oauth2|112662575611526777104"
      // updated_at: "2020-10-19T17:53:07.387Z"
      const newItem = {
        name: title,
        description: description,
        repo: repo,
        attachmentUrl: attachmentUrl,
        userName: user.name,
        picture: user.picture,
        email: user.email,
      };
      await createProject(accessToken, newItem);
      clearForm();
      SetCreatingProjectFlag(false);
      setOpenModal(true);
    } catch (e) {
      alert("Something went wrong. Exception Message ");
      console.log(e);
    }
  };

  if (isCreatingProject) {
    return (
      <Segment>
        <Dimmer active>
          <Loader size="medium">Creating new project...</Loader>
        </Dimmer>
        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  }

  if (openModal) {
    return (
      <Modal
        onClose={() => setOpenModal(false)}
        onOpen={() => setOpenModal(true)}
        open={openModal}
      >
        <Modal.Header>Content Verification Check</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              We are analyzing the content of your request. Your project will be
              available shortly
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)} primary>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  return (
    <div>
      {isAuthenticated ? (
        <Container textAlign="left">
          <Form onSubmit={onFormSubmit}>
            <Form.Field
              label="Title"
              control={Input}
              placeholder="Title: Be clear about your title e.g. Serverless app with SQS and Step functions"
              value={title}
              onChange={handleTitleChange}
            />
            <Form.Field
              label="Description"
              control={TextArea}
              placeholder="Description: Use no less than 100 word in your description"
              value={description}
              onChange={handleDescriptionChange}
            />
            <Form.Field
              label="GitHub Repository"
              control={Input}
              placeholder="https://github.com/......"
              value={repo}
              onChange={handleGitHubChange}
            />
            <Form.Field width={4}>
              <Button
                id="upload-file-button"
                as="label"
                htmlFor="file"
                type="button"
              >
                {newFile.name ? newFile.name : "Upload Schematic(.jpg, .png)"}
              </Button>
              <input type="file" id="file" hidden onChange={fileChange} />
            </Form.Field>

            <Form.Field control={Button} disabled={newFile.name === undefined}>
              Submit
            </Form.Field>
          </Form>
        </Container>
      ) : (
        <Header as="h3">Please LogIn</Header>
      )}
    </div>
  );
};
export default CreateProject;
