import React from "react";
import { Container, Menu, Image, Grid, Message } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../logout-button";
import LoginButton from "../login_button";
import Loading from "../loading";
import { Link, Redirect } from "react-router-dom";

const Login = () => {
  const state = { activeItem: "inbox" };

  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  const renderAuthButton = () => {
    return (
      <Menu.Menu position="right">
        {isAuthenticated ? (
          <div>
            <Menu.Item>
              <LogoutButton />
              <div id="avatar-img-div1" style={{ marginLeft: "10px" }}>
                <Image id="avatar-img" src={user.picture} avatar />
              </div>
            </Menu.Item>
          </div>
        ) : (
          <Menu.Item>
            <LoginButton />
          </Menu.Item>
        )}
      </Menu.Menu>
    );
  };

  return (
    <Container>
      <Menu>
        <Menu.Item
          name="home"
          active={state.activeItem === "home"}
          as={Link}
          to={"/"}
        />
        {renderAuthButton()}
      </Menu>
      {isAuthenticated ? (
        <Redirect to="/projects" />
      ) : (
        <Grid>
          <Grid.Row>
            <Message>
              <Message.Header>
                Please Log In to create/access your projects.
              </Message.Header>
              <p>
                Once you are logged in, you will be able to create projects.
                Each project contains a title, description ,github repository
                and image or schematic. I will not touch your code directly and
                will always create a new branch to work toward your doubt or
                issue.
                <i>
                  Disclaimer: Once you log into this application, I will have
                  access to your name ,email and avatar.
                  <p>
                    <b>
                      This site is still under construction and some
                      functionality could not be available. The expected release
                      day is December 31st 2020.
                    </b>
                  </p>
                </i>
              </p>
            </Message>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
};
export default Login;
