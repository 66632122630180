import React from "react";
import { Container, Image, Grid, Card, Icon, Header } from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import default_projectImag from "../../media/image/Serverless_Udagram_App_v.1.png";

const ProjectList = (props) => {
  const { isAuthenticated } = useAuth0();
  // const projects = props.projects;
  const projects_components = props.listOfProjects.map((item, index) => {
    return (
      <Container key={index}>
        {isAuthenticated && (
          <Grid celled>
            <Grid.Row>
              <Grid.Column width={5}>
                <Card>
                  <Card.Content extra>
                    <Image id="avatar-img" src={item.picture} avatar />{" "}
                    {item.userName}
                  </Card.Content>
                  <Image
                    src={
                      item.attachmentUrl
                        ? item.attachmentUrl
                        : default_projectImag
                    }
                    wrapped
                    ui={false}
                  />
                  <Card.Content extra>
                    <Icon size="big" link name="download" />
                    <Icon size="big" link name="github" />
                    <Icon size="big" link name="like" />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column width={11}>
                <Container text textAlign="left" color="black">
                  <Header as="h3"> {item.name}</Header>
                  <p style={{ color: "black" }}>{item.description}</p>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    );
  });

  // console.log(projects_components);

  return (
    <div>
      <>{projects_components}</>
    </div>
  );
};

export default ProjectList;
