import React from "react";
import { Container, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CoursesTable from "./courses/Courses_Table";

const Works = () => {
  return (
    <Container
      text
      textAlign="justified"
      color="black"
      style={{ marginTop: "3em" }}
    >
      <Header as="h2">Courses, Talks and Some other topics</Header>
      <Header as="h3">Courses</Header>
      <Header as="h4">In a few words what is this about?</Header>

      <p style={{ color: "black" }}>
        These are hands-on advanced courses for developers about technologies
        and programming skills, taught in person by{" "}
        <Link to="/"> David Gutierrez </Link> for people who want to learn and
        deep their knowledge about new technologies. It is intended primary for
        developers{" "}
      </p>

      <Header as="h4">
        Deep your knowledge and exploit new opportunities{" "}
      </Header>

      <p style={{ color: "black" }}>
        I have found multiple times that developers are so overwhelmed with
        their current tasks that learning some new technologies would take them
        an immense effort. They know that technologies will eventually change
        and the programming skills will remain. But then there are different
        kinds of technologies. I concentrate on those technologies that are in
        an stage ready to be exploited for a developer advantage. Technologies
        that will empower them and help them consume less time and effort in
        their day to day job.Most importantly, technologies that they are about
        to be mandated to adopt. Currently, I think cloud and artificial
        intelligence are the technology of the present. BlockChain, Webassembly
        and Quantum computing are some how in a near future ready for developers
        to be exploited. On my courses, I explain from a developer point of
        view, by coding everything how they can use those technologies and the
        best pattern they should adopt and learn and why. That will in turn help
        them create more robust, secure, cost and time efficient applications,
        the kind of application that their company or projects are demanding
        from them.
      </p>

      <Header as="h4">Current Courses Schedule</Header>
      <CoursesTable></CoursesTable>
    </Container>
  );
};

export default Works;
