import React, { Component } from "react";
import { Header, Image, Grid } from "semantic-ui-react";
import twitter_logo from "../../media/image/Twitter_Logo_WhiteOnBlue.png";
import linkedin_logo from "../../media/image/LinkedInIcon.jpg";
import github_logo from "../../media/image/GitHub-Mark-Light-120px-plus.png";

export class Contact extends Component {
  render() {
    return (
      <>
        <div style={{ backgroundColor: "#00608b" }}>
          <Header as="h2" style={{ color: "white", paddingTop: "1.5em" }}>
            Let's get in Contact
          </Header>

          <Grid columns={3} divided="vertically">
            <Grid.Row>
              <Grid.Column>
                <a
                  href="https://twitter.com/Py_Miami"
                  className="ui tiny image"
                >
                  <Image src={twitter_logo} />
                </a>
              </Grid.Column>
              <Grid.Column>
                <a
                  href="https://www.linkedin.com/in/davidtgutierrez/"
                  className="ui tiny image"
                >
                  <Image src={linkedin_logo} />
                </a>
              </Grid.Column>
              <Grid.Column>
                <a
                  href="https://github.com/david68cu"
                  className="ui tiny image"
                >
                  <Image src={github_logo} />
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header as="h3" style={{ color: "white", paddingBottom: "1.5em" }}>
            If you are around Miami, get in contact and let's talk !!
          </Header>
        </div>
      </>
    );
  }
}
