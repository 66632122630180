import React, { Component } from "react";
import { AboutMe } from "./AboutMe";
import { MyInterest } from "./MyInterest";
import { LessonLearned } from "./LessonLearned";
import { AchievingYourGoals } from "./AchievingYourGoals";
import { MySystem } from "./MySystem";
import { Contact } from "./Contact";
import { Projects } from "./MyProjects";
// import {IdealPositions} from "./IdealPositions";
import { CurrentSkills } from "./CurrentSkills";
import "semantic-ui-css/semantic.min.css";
import "./Home.css";
import { MyPhoto } from "./MyPhoto";
import { MyCourses } from "./MyCourses";

class Home extends Component {
  render() {
    return (
      <div>
        <MyPhoto />

        <AboutMe />

        <MyInterest />

        <LessonLearned />

        <AchievingYourGoals />

        <MySystem />

        <CurrentSkills />

        <MyCourses />

        <Projects />

        <Contact />
      </div>
    );
  }
}

export default Home;
